import Theme from "../components/Theme";
import Script from "next/script";
import "./styles.css";
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';

import { Amplify } from "aws-amplify";

if (process.env.NEXT_PUBLIC_DOMAIN == "searchexhaust.com") {
  Amplify.configure({
    region: "us-east-1",
    userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
  });
} else {
  Amplify.configure({
    region: "us-east-1",
    userPoolId: process.env.NEXT_PUBLIC_DEV_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_DEV_USER_POOL_WEB_CLIENT_ID
  });
}

import ThemeProvider from "../components/ThemeProvider";
import SearchProvider from "../components/SearchProvider";

function SearchExhaust({ Component, pageProps }) {
  return (
    <>
      {/* Google Analytics */}
      <Script id="google-tag-manager" strategy="worker">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P3DWGQF');
      `}
      </Script>

      <Authenticator.Provider>
        <ThemeProvider>
          <SearchProvider>
            <Theme>
              <Component {...pageProps} />
            </Theme>
          </SearchProvider>
        </ThemeProvider>
      </Authenticator.Provider>
    </>
  );
}

export default SearchExhaust;
